const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const passwordRegex = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/);
const withoutSpecialCharactersRegex = new RegExp(/^([a-z]+[\s]*[a-z]+[\s]*)+$/);

export const DEFAULT_NO_ERROR = undefined;

interface IValidatorsProps {
	value: any;
	label?: string;
	minLength?: number;
	maxLength?: number;
}

interface IObjectValidatorsProps {
	value: object;
	label: string;
}

interface IComparePasswordsProps {
	password1: string | undefined | null;
	password2: string | undefined | null;
	label1?: string | undefined | null;
	label2?: string | undefined | null;
}

export const fieldValidator = ({ value, label = '', minLength = 0, maxLength = 0 }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	if (minLength !== 0 && maxLength !== 0) {
		if (value.length < minLength || value.length > maxLength) {
			return `El campo ${label} debe tener entre ${minLength} y ${maxLength} caracteres.`;
		}
	}
	if (minLength !== 0 && value.length < minLength) {
		return `El campo ${label} debe tener al menos ${minLength} caracteres.`;
	}
	if (maxLength !== 0 && value.length > maxLength) {
		return `El campo ${label} debe tener máximo ${maxLength} caracteres.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredValidator = ({ value, label = '' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredNumber = (
	{ value, label = '' }: IValidatorsProps,
	isAnInteger: boolean = false,
	notIncludeNegative: boolean = false
) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	if (isNaN(value)) {
		return `El campo ${label} no es un número.`;
	}
	if (isAnInteger) {
		if (parseFloat(value) - parseInt(value) !== 0) {
			return `El campo ${label} debe ser un número entero.`;
		}
	}
	if (notIncludeNegative) {
		if (parseFloat(value) < 0) {
			return `El campo ${label} no puede ser un número negativo.`;
		}
	}
	return DEFAULT_NO_ERROR;
};

export const requiredRangeNumber = (
	{ value, label = '' }: IValidatorsProps,
	isAnInteger: boolean = false,
	notIncludeNegative: boolean = false,
	min: number,
	max: number
) => {
	let message = requiredNumber({ value, label }, isAnInteger, notIncludeNegative);
	if (message !== DEFAULT_NO_ERROR) {
		return message;
	}
	let numero = parseFloat(value);
	if (numero < min || numero > max) {
		return `El campo ${label} debe encontrarse entre (${min} y ${max}).`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredRangeNumberByLength = (
	{ value, label = '' }: IValidatorsProps,
	isAnInteger: boolean = false,
	notIncludeNegative: boolean = false,
	min: number,
	max: number
) => {
	let message = requiredNumber({ value, label }, isAnInteger, notIncludeNegative);
	if (message !== DEFAULT_NO_ERROR) {
		return message;
	}
	let numero = parseFloat(value);
	if (value.length < min || value.length > max) {
		return min == max
			? `El campo ${label} debe tener (${max}) dígitos.`
			: `El campo ${label} debe tener entre (${min} y ${max}) dígitos.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredPartialNomenclatureCode = ({ value, label = '' }: IValidatorsProps) => {
	let message = requiredRangeNumber({ value, label }, true, true, 0, 99);
	if (message !== DEFAULT_NO_ERROR) {
		return message;
	}
	let numero = parseInt(value);
	if (numero < 10 && value.length !== 2) {
		return `El campo ${label} debe tener el formato ##, donde # es un número entero. Por ejemplo: 05, 09, 10 o 89.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredYear = ({ value, label = '' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	if (isNaN(value)) {
		return `El campo ${label} no es un año en formato de número válido.`;
	}
	if (value?.toString()?.trim().length !== 4) {
		return `El campo ${label} debe ser un año en formato AAAA dónde A es un número.`;
	}
	return DEFAULT_NO_ERROR;
};

export const objectRequiredValidator = ({ value, label = '' }: IObjectValidatorsProps) => {
	if (!value) {
		return `El campo ${label} es requerido.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredWithoutSpecialCharactersValidator = ({ value, label = '' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}

	if (!withoutSpecialCharactersRegex.test(value)) {
		return 'El campo no debe contener caracteres especiales, como tildes, diéresis u otros.';
	}
	return DEFAULT_NO_ERROR;
};

export const requiredListValidator = ({ value, label = 'Elemento' }: IValidatorsProps) =>
	value ? DEFAULT_NO_ERROR : `Seleccione un ${label} de la lista.`;

export const passwordValidator = ({ value, label = 'Contraseña' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	if (!passwordRegex.test(value)) {
		return 'El campo Contraseña debe contener al menos 8 caracteres, una letra mayúscula, letras minúsculas, números y al menos un caracter especial.';
	}
	return DEFAULT_NO_ERROR;
};

export const emailValidator = ({ value }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return 'El campo Correo Electrónico es requerido.';
	}
	if (!emailRegex.test(value)) {
		return 'El campo Correo Electrónico no posee un formato válido.';
	}
	return DEFAULT_NO_ERROR;
};

export const passwordsAreEquals = ({
	password1,
	label1 = 'Contraseña',
	password2,
	label2 = 'Repita la contraseña'
}: IComparePasswordsProps) => {
	let message = `Los campos ${label1} y ${label2} no coinciden.`;
	if (
		requiredValidator({ value: password1 }) === DEFAULT_NO_ERROR &&
		requiredValidator({ value: password2 }) === DEFAULT_NO_ERROR
	) {
		if (password1 !== password2) {
			return message;
		}
	} else {
		return message;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredDate = ({ value, label = '' }: IValidatorsProps) => {
	let example = new Date(value?.toString()).toString();
	if (example == 'Invalid Date') {
		return `El campo ${label} no es una fecha válida.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredNomenclatureCode = ({ value, label = '' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	let numbers = value.split('.');
	if (numbers.length !== 4) {
		return `El campo ${label} debe contener el formato ##.##.##.##. Donde # es un número entero positivo.`;
	}
	let i = 0;
	let hasError = false;
	while (i < numbers.length && !hasError) {
		if (isNaN(numbers[i]) || parseInt(numbers[i]) < 1) {
			hasError = true;
		}
		i++;
	}
	if (hasError) {
		return `El campo ${label} debe contener el formato ##.##.##.##. Donde # es un número entero positivo.`;
	}
	return DEFAULT_NO_ERROR;
};

export const requiredCUITCUILValidator = ({ value, label = '' }: IValidatorsProps) => {
	if (!value || !value?.toString()?.trim()) {
		return `El campo ${label} es requerido.`;
	}
	if (isNaN(value)) {
		return `El campo ${label} no es un número válido.`;
	}
	if (value?.toString()?.trim().length < 11) {
		return `El campo ${label} debe contener 11 caracteres.`;
	}
	return DEFAULT_NO_ERROR;
};
