import { FC, useState, useMemo, useEffect, ChangeEvent, useCallback } from 'react';

//Hooks
import { useMovContext } from 'hooks/useMovContext';

//Services
import { getAfipUser } from 'services/getUserAfip';
import { IPersonaAfip } from 'interfaces/IPersonaAfip';

//Components
import { ITipoAltaDonacionProps } from 'components/EncPatrimonial/altaStepper/paso1/interfaces';

//Material UI
import { Box, TextField, Typography } from '@mui/material';
import { BeatLoader } from 'react-spinners';
import { usePromiseTracker } from 'react-promise-tracker';

export const DonacionEdit: FC<ITipoAltaDonacionProps> = ({ setPaso1Donacion }) => {
	const movContext = useMovContext();
	const [userLocal, setUserLocal] = useState<any>({} as any);
	const [userError, setUserError] = useState<string | null>(null);
	const [cuit, setCuit] = useState(movContext?.editMovement?.property_registration?.single_tax_id || '');
	const [donante, setDonante] = useState(movContext?.editMovement?.property_registration?.donor_name || '');

	const { promiseInProgress } = usePromiseTracker({ area: 'altaPaso1' });

	const memoUser = useCallback(async () => {
		if (cuit.trim().length === 11 && userLocal?.nombre === undefined) {
			await getAfipUser(cuit).then((res) => {
				if (!res?.data) {
					const error = res.replace('Id', 'Cuil/Cuit');
					setUserError(error);
					return;
				}
				setUserError(null);
				delete res.data.metadata;
				if (res.data.persona.tipoPersona === 'FISICA') {
					setUserLocal({
						nombre: res.data.persona.nombre,
						apellido: res.data.persona.apellido,
						domicilio: `
								${res.data.persona.domicilio[0]?.codPostal || ''} 
								${res.data.persona.domicilio[0]?.localidad || ''} 
								${res.data.persona.domicilio[0]?.descripcionProvincia || ''} 
								${res.data.persona.domicilio[0]?.tipoDatoAdicional || ''} 
								${res.data.persona.domicilio[0]?.direccion || ''}`
					});
				} else {
					setUserLocal({
						nombre: res.data.persona.razonSocial,
						domicilio: `
								${res.data.persona.domicilio[0]?.codPostal || ''} 
								${res.data.persona.domicilio[0]?.localidad || ''} 
								${res.data.persona.domicilio[0]?.descripcionProvincia || ''} 
								${res.data.persona.domicilio[0]?.tipoDatoAdicional || ''} 
								${res.data.persona.domicilio[0]?.direccion || ''}`
					});
				}
				return;
				return;
			});
		}
	}, [cuit]);

	useEffect(() => {
		let isCancelled = false;
		if (!isCancelled) {
			memoUser();
		}
		return () => {
			isCancelled = true;
		};
	}, [cuit]);

	const handleUser = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		evt.preventDefault();
		setUserLocal({ ...userLocal, ...{ [evt.target.name]: evt.target.value } });
	};

	useEffect(() => {
		setPaso1Donacion({ ...userLocal, cuit, donante, tipoAlta: 'donacion' });
	}, [userLocal, cuit, donante]);

	const handleCuit = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		evt.preventDefault();
		const { value } = evt.target;
		if (value.length < 11) {
			setUserLocal({} as IPersonaAfip);
		}
		const regex = /^[0-9]{0,11}$/g;
		if (regex.test(value)) {
			setCuit(value);
		}
	};

	return (
		<>
			<Box
				sx={{
					width: '250px',
					mr: 2,
					display: 'flex',
					flexDirection: 'column'
				}}>
				<TextField
					autoComplete="off"
					name="cuit"
					id="cuit"
					label="N° de CUIL/CUIT"
					value={cuit}
					onChange={handleCuit}
					sx={{ width: '250px', mr: 2, mb: '1px' }}
				/>
				{promiseInProgress && (
					<Box
						id="spinner"
						sx={{ pt: 2, alignSelf: 'center' }}>
						<BeatLoader
							size={25}
							color={'#1A3762'}
						/>
					</Box>
				)}
				{userError !== null && (
					<Box sx={{ alignSelf: 'center', mb: 3 }}>
						<Typography sx={{ fontSize: '12px', ml: 3 }}>*{userError}</Typography>
						<Typography sx={{ fontSize: '12px', ml: 3 }}>*Ingrese Manualmente los siguientes Datos</Typography>
						<TextField
							size="small"
							autoComplete="off"
							name="nombre"
							id="nombre"
							label="Nombre o Razón Social"
							value={userLocal.persona?.nombre}
							onChange={handleUser}
							sx={{ width: '250px', mr: 2, ml: 2, mb: '10px', mt: '10px' }}
						/>
						<TextField
							size="small"
							autoComplete="off"
							name="domicilioUnico"
							id="domicilioUnico"
							label="Domicilio"
							value={userLocal.persona?.domicilioUnico}
							onChange={handleUser}
							sx={{ width: '250px', mr: 2, ml: 2, mb: '1px' }}
						/>
					</Box>
				)}
				{cuit?.length === 11 && userLocal?.domicilio !== undefined && userError === null && (
					<>
						<Typography sx={{ p: 1, fontWeight: '500' }}>{`${userLocal?.nombre || ''} ${userLocal?.apellido || ''}`}</Typography>
						<Box sx={{ p: 1, pt: 0, mb: 2 }}>
							<Typography>{`${userLocal?.domicilio}`}</Typography>
						</Box>
					</>
				)}
			</Box>
			{}
			<Box sx={{ width: '250px', mr: 2, mt: 2 }}>
				<TextField
					autoComplete="off"
					name="donante"
					id="donante"
					label="Donante"
					value={donante}
					onChange={(e) => {
						setDonante(e.target.value);
					}}
					sx={{ width: '250px', mr: 2 }}></TextField>
			</Box>
		</>
	);
};
