import { ChangeEvent, FC, useState, useEffect, useRef, useCallback, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import style from './adquisicion.module.css';

// Services
import { toast } from 'react-toastify';
import { getAfipUser } from 'services/getUserAfip';
import { getVoucherTypes } from 'services/crudService';
import { usePromiseTracker } from 'react-promise-tracker';
import { fileValidate, formValidateAdquisicion } from 'components/EncPatrimonial/altaStepper/paso1/validate';

// Hooks
import { useMovContext } from 'hooks/useMovContext';

// Interfaces
import { IPersonaAfip } from 'interfaces/IPersonaAfip';
import { IAdquisicionValues, ITipoAltaAdquisicionProps } from 'components/EncPatrimonial/altaStepper/paso1/interfaces';

//Components
import { Ncomprobante } from './Ncomprobante';

// Enums
import { FileType, PropertyType } from 'enums';

// Material UI
import { BeatLoader } from 'react-spinners';
import {
	SelectChangeEvent,
	Typography,
	Box,
	TextField,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Tooltip
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface IComprobantes {
	id: number;
	name: string;
}

const AdquisicionEdit: FC<ITipoAltaAdquisicionProps> = memo(
	({ setPaso1Adquisicion, paso1Adquisicion, tipoAlta }: ITipoAltaAdquisicionProps) => {
		const movContext = useMovContext();
		const [cuit, setCuit] = useState(movContext?.editMovement?.property_registration?.single_tax_id || '');
		const [user, setUser] = useState<IPersonaAfip>({} as IPersonaAfip);
		const [comprobanteList, setComprobanteList] = useState<IComprobantes[]>([]);
		const [comprobante, setComprobante] = useState('');
		const [ncomprobante, setNcomprobante] = useState(movContext?.editMovement?.property_registration?.invoice_number?.split('-')[1] || '');
		const [puntoVenta, setPuntoVenta] = useState(movContext?.editMovement?.property_registration?.invoice_number?.split('-')[0] || '');
		const [userError, setUserError] = useState<string | null>(null);
		const [errorFile, setErrorFile] = useState<string | null>(null);
		const [file, setFile] = useState<File>({} as File);
		const navigate = useNavigate();

		const adjunto = useRef<HTMLInputElement>(null);

		const { promiseInProgress } = usePromiseTracker({ area: 'altaPaso1' });

		useEffect(() => {
			getVoucherTypes()
				.then((res) => {
					setComprobanteList(res.data);
					if (!res.data) {
						throw new Error('No se pudo obtener los tipos de comprobantes');
					}
					if (movContext?.editMovement?.property_registration?.voucher_type_id) {
						setComprobante(
							res.data.find((c: any) => c.id === movContext?.editMovement?.property_registration?.voucher_type_id)?.name || ''
						);
					}
				})
				.catch((err) => {
					toast(err.message, {
						type: 'error',
						onClose: () => {
							navigate('/', { replace: true });
						}
					});
				});
		}, []);

		useEffect(() => {
			memoUser();
		}, []);

		/**
		 * `handleUser` is a function that takes an event as an argument, prevents the default action, and sets
		 * the state of the user object to the current state of the user object with the value of the target
		 * name and value
		 * @param evt - ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		 */
		const handleUser = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			evt.preventDefault();
			setUser({ ...user, persona: { ...user.persona, [evt.target.name]: evt.target.value } });
		};

		/* A hook that is called after the first render.
		 *
		 * It is called to check CUIT input and if it is valid, it set UserError to null,
		 * and call the service getAfipUser to get the user data.
		 *
		 */
		const memoUser = useCallback(async () => {
			setUserError(null);
			setUser({} as IPersonaAfip);

			if (cuit.trim().length === 11) {
				await getAfipUser(cuit).then((res) => {
					if (!res?.data) {
						const error = res.replace('Id', 'Cuil/Cuit');
						setUserError(error);
						setUser({} as IPersonaAfip);
						return;
					}
					delete res.data.metadata;
					if (res.data.persona.tipoPersona !== 'FISICA') {
						setUser({
							...user,
							persona: { domicilio: res.data.persona.domicilio || '', nombre: res.data.persona.razonSocial || '' }
						});
					} else {
						setUser({
							...user,
							persona: {
								domicilio: res.data.persona.domicilio || '',
								nombre: res.data.persona.nombre || '',
								apellido: res.data.persona.apellido || ''
							}
						});
					}
				});
			}
		}, [user, cuit]);

		/* A hook that is called after the first render.
		 * It is called to check CUIT input and if it is valid, it calls the memoUser hook.
		 */
		useEffect(() => {
			let isCancelled = false;
			if (!isCancelled) {
				memoUser();
			}
			return () => {
				isCancelled = true;
			};
		}, [cuit]);

		/* A function that is being called after the first render.
		 * It check if all values are valids.
		 * Save on localStorage the values.
		 */
		const checkValidation = useCallback(() => {
			const newComprobante = comprobanteList.find((item) => item.name === comprobante);

			const adquisicion: IAdquisicionValues = {
				tipoAlta,
				cuit,
				user,
				comprobante: newComprobante?.id || 0,
				ncomprobante,
				file,
				errorFile,
				userError,
				puntoVenta
			};
			const validate = formValidateAdquisicion(adquisicion);
			if (validate) {
				delete adquisicion?.errorFile;
				setPaso1Adquisicion(adquisicion);
			} else {
				setPaso1Adquisicion(null);
			}
		}, [
			tipoAlta,
			cuit,
			user.persona?.nombre,
			user.persona?.domicilio,
			user.persona?.domicilioUnico,
			comprobante,
			ncomprobante,
			puntoVenta,
			file,
			errorFile
		]);

		/* A hook that is called after the first render. */
		useEffect(() => {
			checkValidation();
		}, [checkValidation]);

		/**
		 * Function who deletes the file.
		 */
		const deleteFile = () => {
			if (adjunto?.current) {
				adjunto.current.value = '';
			}
			setFile({} as File);
		};

		/**
		 * A function that handles the change of COMPROBANTE select element.
		 * @param {SelectChangeEvent} evt - SelectChangeEvent
		 */
		const handleComprobante = (evt: SelectChangeEvent) => {
			evt.preventDefault();
			setComprobante(evt.target.value);
		};

		/**
		 * A function that handles the change of the CUIT input field.
		 * @param evt - ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		 */
		const handleCuit = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			evt.preventDefault();
			const { value } = evt.target;
			if (value.length < 11) {
				setUser({} as IPersonaAfip);
			}
			const regex = /^[0-9]{0,11}$/g;
			if (regex.test(value)) {
				setCuit(value);
			}
		};

		/**
		 * A function that handles the change of the NCOMPROBANTE input field.
		 * @param evt - ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
		 */
		const handleNcomprobante = (evt: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
			const { value } = evt.target;
			setNcomprobante(value);
		};

		/**
		 * `const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {`
		 *
		 * The `handleFileChange` function is a function that takes an event of type
		 * `ChangeEvent<HTMLInputElement>` and returns nothing
		 * @param event - ChangeEvent<HTMLInputElement>
		 * @returns The file is being returned.
		 */
		const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
			event.preventDefault();
			const file = event.target.files?.[0];
			if (!file) return;
			if (file && fileValidate(file) === null) {
				const newFile = new File([file], file.name.toLocaleLowerCase(), { type: file.type, lastModified: file.lastModified });
				setFile(newFile);
				setErrorFile(null);
			} else {
				setErrorFile(fileValidate(file));
			}
		};

		/**
		 * Check if the movement have a file. If it does, it set a fake file to show it.
		 */
		useEffect(() => {
			if (movContext?.editMovement?.property_registration?.files) {
				movContext?.editMovement?.property_registration?.files.map((file: any) => {
					if (file?.file_type_id === FileType.Factura_y_o_Remito) {
						setFile(file);
					}
				});
			}
		}, []);

		useEffect(() => {
			if (file && file?.name) {
				const fileFromBE = movContext?.editMovement?.property_registration?.files?.find(
					(file: any) => file?.file_type_id === FileType.Factura_y_o_Remito
				);
				if (fileFromBE) {
					if (fileFromBE?.name && file?.size > 0) {
						const newFiles = movContext?.recepAndInstAttachs;
						if (newFiles && newFiles?.length === 0 && movContext?.editMovement?.property_registration?.id) {
							newFiles.push({
								id: fileFromBE?.id,
								idProperty: movContext?.editMovement?.property_registration?.id,
								name: fileFromBE?.name,
								operation: 'delete',
								key: 'reception'
							});
							newFiles.push({
								idProperty: movContext?.editMovement?.property_registration?.id,
								name: file?.name,
								file,
								operation: 'create',
								key: 'reception'
							});
							movContext?.setRecepAndInstAttachs([...newFiles]);
							return;
						}
						if (newFiles && newFiles?.length > 0 && movContext?.editMovement?.property_registration?.id) {
							const filterFiles = newFiles?.filter((file: any) => file?.key !== 'reception');
							filterFiles.push({
								id: fileFromBE?.id,
								idProperty: movContext?.editMovement?.property_registration?.id,
								name: fileFromBE?.name,
								operation: 'delete',
								key: 'reception'
							});
							filterFiles.push({
								idProperty: movContext?.editMovement?.property_registration?.id,
								name: file?.name,
								file,
								operation: 'create',
								key: 'reception'
							});
							movContext?.setRecepAndInstAttachs([...filterFiles]);
							return;
						}
					}
				}
			} else {
				movContext?.setRecepAndInstAttachs((prev) => prev.filter((file) => file.key !== 'reception'));
				return;
			}
			if (
				movContext?.editMovement?.property_registration?.high_type_id === PropertyType.Donation ||
				movContext?.editMovement?.property_registration?.high_type_id === PropertyType.Transfer
			) {
				const movementsInProvider = movContext?.recepAndInstAttachs?.filter((file) => file.key !== 'reception');
				if (movementsInProvider && movContext?.editMovement?.property_registration?.id) {
					movementsInProvider.push({
						idProperty: movContext?.editMovement?.property_registration?.id,
						name: file?.name,
						file,
						operation: 'create',
						key: 'reception'
					});
					movContext?.setRecepAndInstAttachs([...movementsInProvider]);
				}
			}
		}, [file, adjunto]);

		return (
			<Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
				<Box
					sx={{
						mr: 2,
						display: 'flex',
						flexWrap: 'wrap',
						flexDirection: 'row'
					}}>
					<TextField
						autoComplete="off"
						name="cuit"
						id="cuit"
						label="N° de CUIL/CUIT *"
						value={cuit}
						onChange={handleCuit}
						sx={{ width: '250px', mr: 2, mb: '1px' }}
					/>
					{promiseInProgress && !user?.persona && (
						<Box
							id="spinner"
							sx={{ pt: 0, alignSelf: 'center' }}>
							<BeatLoader
								size={25}
								color={'#1A3762'}
							/>
						</Box>
					)}
					{cuit.length > 0 && cuit.length < 11 && (
						<Box sx={{ alignSelf: 'center', mb: 2, width: '100%' }}>
							<Typography sx={{ fontSize: '12px', ml: 1, mt: 1, color: '#8d8d8d' }}>* CUIL/CUIT debe contener 11 dígitos</Typography>
						</Box>
					)}
					{userError !== null && (
						<Box sx={{ alignSelf: 'center', mb: 2, width: '100%' }}>
							<Typography sx={{ fontSize: '12px', ml: 1 }}>*{userError}</Typography>
							<Typography sx={{ fontSize: '12px', ml: 1 }}>*Ingrese Manualmente los siguientes Datos</Typography>
							<Box sx={{ ml: -2, mt: 2, display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}>
								<TextField
									size="small"
									autoComplete="off"
									name="nombre"
									id="nombre"
									label="Nombre o Razón Social *"
									value={user.persona?.nombre}
									onChange={handleUser}
									sx={{ width: '250px', mr: 2, ml: 2, mb: 2 }}
								/>
								<TextField
									size="small"
									autoComplete="off"
									name="domicilioUnico"
									id="domicilioUnico"
									label="Domicilio *"
									value={user.persona?.domicilioUnico}
									onChange={handleUser}
									sx={{ width: '250px', mr: 2, ml: 2, mb: '1px' }}
								/>
							</Box>
						</Box>
					)}
					{cuit?.length === 11 && user?.persona?.domicilio !== undefined && userError === null && (
						<Box className={style.dataUser}>
							<Typography sx={{ pl: 1, pt: 0.5, fontWeight: '500' }}>{`${user?.persona?.nombre || ''} ${
								user?.persona?.apellido || ''
							}`}</Typography>
							<Box sx={{ p: 1, pt: 0 }}>
								{user?.persona?.domicilio?.slice(0, 1).map((data, index) => (
									<Typography key={index}>{`${data.direccion || ''} ${data.localidad || ''} ${
										data.descripcionProvincia || ''
									}`}</Typography>
								))}
							</Box>
						</Box>
					)}
				</Box>
				<Box className={style.comprobantes}>
					<Box>
						<FormControl>
							<InputLabel id="comprobanteLabel">Tipo Comprobante *</InputLabel>
							<Select
								labelId="comprobanteLabel"
								name="comprobante"
								label="Tipo Comprobante *"
								id="comprobante"
								value={comprobanteList.length > 0 ? comprobante : ''}
								onChange={handleComprobante}
								sx={{ width: '200px' }}>
								{comprobanteList ? (
									comprobanteList?.map((data, index) => (
										<MenuItem
											key={index - 1}
											value={data.name}>
											{data.name}
										</MenuItem>
									))
								) : (
									<MenuItem
										key={0}
										value={''}>
										{'Cargando...'}
									</MenuItem>
								)}
							</Select>
						</FormControl>
					</Box>
					<Box className={style.nComprobante}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Ncomprobante
								ncomprobante={ncomprobante}
								handleNcomprobante={handleNcomprobante}
								errorFile={errorFile}
								adjunto={adjunto}
								handleFileChange={handleFileChange}
								puntoVenta={puntoVenta}
								setPuntoVenta={setPuntoVenta}
							/>
						</Box>
						<div style={{ display: 'flex', flexWrap: 'nowrap', alignSelf: 'end', alignItems: 'center', marginBottom: '5px' }}>
							<Box>
								{file && file?.name && (
									<>
										<Typography
											sx={{
												pl: 1,
												width: '200px',
												wordBreak: 'break-all'
											}}>
											{file.name}
										</Typography>
									</>
								)}
								{!file?.name && (
									<>
										<FormHelperText sx={{ pt: 0, pl: 2, position: 'absolute' }}>* Requerido comprobante adjunto</FormHelperText>
										<FormHelperText sx={{ pt: 3, pl: 2, position: 'absolute' }}>
											* Extensiones permitidas: .png, .jpeg, .jpg, .pdf
										</FormHelperText>
										<FormHelperText sx={{ pt: 6, pl: 2, position: 'absolute' }}>* Tamaño máximo: 100MB</FormHelperText>
									</>
								)}
							</Box>
							{file && file?.name && (
								<Box>
									<Tooltip
										arrow
										title="Eliminar adjunto">
										<DeleteIcon
											sx={{
												cursor: 'pointer',
												fontSize: '30px',
												backgroundColor: '#575757',
												color: '#fff',
												padding: '3px',
												borderRadius: '5px',
												m: 1,
												mt: 1.2,
												transition: 'all 0.2s ease-in-out',
												'&:hover': {
													transition: 'all 0.2s ease-in-out',
													backgroundColor: '#161616',
													color: '#fff',
													transform: 'scale(1.2)'
												}
											}}
											color="error"
											onClick={deleteFile}
										/>
									</Tooltip>
								</Box>
							)}
						</div>
					</Box>
				</Box>
			</Box>
		);
	}
);

AdquisicionEdit.displayName = 'AdquisicionEdit';

export { AdquisicionEdit };
