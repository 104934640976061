import { FC, useState, useEffect } from 'react';
//Components
import { SearchEntityComponent, ISearchEntityData } from 'components/globals/searchEntity/SearchEntity';
// Material UI
import { Grid, Box } from '@mui/material';
import { DEFAULT_NO_ERROR, requiredValidator } from 'helpers/Validators/Validators';
import { Attachment } from 'interfaces/file/IFile';
import { FechaFactRemito, IFechaComprobante } from '../FechaFactRemito';

export interface IEntityValues {
	identifier?: string;
	name?: string;
	address?: string;
	hasErrorAFIP: boolean;
	dni?: string;
	isDni?: boolean;
	documentType?: string | undefined;
}

export interface IDonacionValues {
	entity?: IEntityValues;
	hasErrors?: boolean;
	errors?: any;
	date?: string;
	observations?: string | undefined;
	attached?: File | undefined;
	dateIntrument?: any | undefined;
	hasErrorAFIP?: boolean;
}

export interface IOverviewDonacionForm {
	entity: IDonacionValues;
	handleChange: (data: IDonacionValues) => void;
	attachments?: Attachment[];
	documentId?: number;
	onDeleteFile?: CallableFunction;
	onTimelessError?: CallableFunction;
}

export interface IErrorsDonacionForm {
	user?: string;
	date?: string;
}

const Donacion: FC<IOverviewDonacionForm> = ({
	entity,
	handleChange,
	attachments,
	documentId,
	onDeleteFile,
	onTimelessError
}: IOverviewDonacionForm) => {
	// Persona u Organismo
	const [user, setUser] = useState<ISearchEntityData | undefined>();
	const [date, setDate] = useState<string | undefined>();
	const [dataFormHasChanged, setDataFormHasChanged] = useState<number>(0);
	// Comprobante Fiscal
	const [donationDetails, setDonationDateDetails] = useState<IFechaComprobante | undefined>();

	// Parámetros generales
	const [errors, setErrors] = useState<IErrorsDonacionForm>({} as IErrorsDonacionForm);

	useEffect(() => {
		if (entity) {
			setDate(entity.date);

			setUser({
				identifier: entity.entity?.identifier,
				address: entity.entity?.address,
				name: entity.entity?.name,
				documentType: entity.entity?.documentType,
				user: {
					domicilio: entity.entity?.address,
					nombre: entity.entity?.name
				},
				dni: entity.entity?.dni,
				hasErrorAFIP: entity.entity?.hasErrorAFIP
			} as ISearchEntityData);

			setDonationDateDetails({
				date: entity.date,
				dateIntrument: entity.dateIntrument,
				observations: entity.observations,
				attached: entity.attached ?? undefined,
				hasErrors: entity.hasErrors ?? false
			});
		}
	}, [entity]);

	const validateForm = (): boolean => {
		const newErrors: IErrorsDonacionForm = {} as IErrorsDonacionForm;
		setErrors(newErrors);

		// if (user?.identifier == undefined || user.name == undefined || user.address == undefined) {
		// 	return false;
		// }

		const dateError = requiredValidator({ value: date, label: 'Fecha de Alta' });
		if (dateError !== DEFAULT_NO_ERROR) {
			newErrors.date = dateError;
		}

		setErrors(newErrors);
		if (Object.entries(newErrors).length !== 0) {
			return false;
		}

		return true;
	};

	const getParsedData = (hasErrors = false): IDonacionValues => {
		return {
			entity: { ...user, isDni: Boolean(entity?.entity?.isDni) },
			date: donationDetails?.date,
			dateIntrument: donationDetails?.dateIntrument,
			attached: donationDetails?.attached,
			observations: donationDetails?.observations,
			hasErrors: hasErrors,
			errors: hasErrors ? errors : ({} as IErrorsDonacionForm),
			hasErrorAFIP: user?.hasErrorAFIP
		} as IDonacionValues;
	};

	useEffect(() => {
		if (dataFormHasChanged) {
			const hasErrors = !validateForm();
			handleChange(getParsedData(hasErrors));
		}
	}, [dataFormHasChanged]);

	return (
		<>
			<Grid
				container
				spacing={2}
				mt={2}>
				<SearchEntityComponent
					defaultDocumentOption={entity?.entity?.isDni ? { label: 'DNI', id: 1 } : { label: 'CUIT/CUIL', id: 2 }}
					registerTypeId={3}
					identifier={user?.identifier}
					name={user?.name}
					address={user?.address}
					user={user?.user}
					handleChange={(data: ISearchEntityData) => {
						setUser({
							identifier: data.identifier,
							name: data.name,
							address: data.address,
							user: data.user,
							dni: data.dni,
							hasErrorAFIP: data.hasErrorAFIP,
							documentType: data.documentType
						} as ISearchEntityData);
						setErrors({ ...data.errors });
						setDataFormHasChanged(dataFormHasChanged + 1);
					}}
				/>
			</Grid>

			<Grid
				container
				spacing={2}>
				<Grid
					item
					xs={12}
					sm={12}
					md={12}
					lg={12}
					xl={12}>
					<Box mt={2}>
						<FechaFactRemito
							onTimelessError={onTimelessError}
							onDeleteFiles={onDeleteFile}
							label="Fecha de Donación *"
							attachments={attachments?.filter((el) => el.file_type_id === 2 || el.file_type_id === 3)}
							entity_id={documentId}
							date={donationDetails?.date}
							observations={donationDetails?.observations}
							attached={donationDetails?.attached}
							dateIntrument={donationDetails?.dateIntrument}
							handleChange={(data: IFechaComprobante) => {
								setDonationDateDetails({
									date: data.date,
									observations: data.observations,
									attached: data.attached,
									dateIntrument: data.dateIntrument
								} as IFechaComprobante);
								setErrors({ ...data.errors });
								setDataFormHasChanged((v) => v + 1);
							}}
						/>
					</Box>
				</Grid>
			</Grid>
		</>
	);
};

Donacion.displayName = 'Donacion';

export { Donacion };
